//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flowableShortcutAdd } from '@/api/modular/flowable/shortcutManage'
import IconSelector from '@/components/IconSelector'
export default {
  components: { IconSelector },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      visibleIcon: false,
      // 圖標組件
      currentSelectedIcon: ''
    }
  },
  methods: {
    // 初始化方法
    add (record) {
      this.visible = true
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            processDefinitionId: record.id,
            category: record.category,
            categoryName: record.categoryName,
            key: record.key,
            version: record.version
          }
        )
      }, 100)
    },
    /**
     * 選擇圖標
     */
    openIconSele () {
      this.visibleIcon = true
    },
    handleIconChange (icon) {
      this.currentSelectedIcon = icon
      this.form.getFieldDecorator('icon', { initialValue: icon })
      this.visibleIcon = false
    },
    handleCancelIcon () {
      this.visibleIcon = false
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          flowableShortcutAdd(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('添加應用成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('添加應用失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.currentSelectedIcon = ''
      this.form.getFieldDecorator('icon', { initialValue: '' })
      this.form.resetFields()
      this.visible = false
    }
  }
}
